*,
html,
body {
  user-select: none;
  outline: none;
}
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.button-authentication {
  display: flex;
  justify-content: center;
  margin-top: 30;
  justify-content: space-around;
  margin-left: 100px;
}

.button-verification {
  text-align: center;
  background-color: white;
  text-align: center !important;
  width: 100%;
  border: 1px solid rgb(230, 235, 241);
  box-shadow: rgb(0 0 0 / 2%) 0px 2px 0px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  user-select: none;
  touch-action: manipulation;
  min-height: 40px;
  padding: 8.5px 1.25rem;
  font-size: 14px;
  border-radius: 0.625rem;
  color: rgb(69, 85, 96);
  background: rgb(255, 255, 255);
}

.input-currency {
  padding-left: 7px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  line-height: 1.5;
  width: 100%;
  border-radius: 10px;
  outline: none;
  max-width: 100%;
  border: 1px solid rgb(230, 235, 241);
  height: 40px;
}

.button-close {
  text-align: end;
  width: '100%';
  cursor: pointer;
  font-size: 20px;
}

@media only screen and (max-width: 700px) {
  .button-authentication {
    display: flex;
    flex-direction: column;
  }
  .button-authentication {
    display: flex;
    justify-content: flex-end;
    margin-top: 30;
    margin-left: 0px;
  }

  .button-responsive {
    flex-wrap: wrap;
    width: 90%;
  }
  .button-close {
    text-align: end;
    width: '100%';
    cursor: pointer;
    margin-bottom: -60px !important;
    font-size: 20px;
  }
}
